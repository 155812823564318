import { useEffect, useState } from 'react';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import { PackRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/pack_rest';
import { Get } from '../client';
import { fromPacksDTOToEntities } from '../../mapper/packMapper';
import { Pack } from '../../interfaces/entity/pack';
import { useModeContext } from '../../provideAppContext';
import { All } from '../../interfaces/entity/allEntity';
import { fromBaseFilterToDTOMapper } from '../../mapper/baseFilterMapper';
import { BaseFilter } from '../../interfaces/entity/baseFilter';
import { BaseFilter as BaseFilterDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { PACKS_BASE_PATH } from './config';

export const usePacks = (
    defaultValue: Pack[],
    defaultFilters: BaseFilter,
): [packs: Pack[], total: number] => {
    const [data, setData] = useState<Pack[]>(defaultValue);
    const [total, setTotal] = useState<number>(0);
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const modeContext = useModeContext();
    useEffect(() => {
        if (isNotUndefined(defaultFilters)) {
            const res = Get<All<PackRest>, BaseFilterDTO>(
                PACKS_BASE_PATH,
                token,
                modeContext,
                fromBaseFilterToDTOMapper(defaultFilters),
            );
            res.then((body) => {
                const packs = fromPacksDTOToEntities(body.data);
                setData(packs);
                setTotal(body.total);
            }).catch((err) => {
                setData([]);
                setTotal(0);
            });
        }
    }, [defaultFilters]);

    return [data, total];
};

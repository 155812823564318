import { ReactElement, memo } from 'react';
import List from '@mui/material/List';

import Paper from '@mui/material/Paper';
import ListItemLink from '../components/navigation/listItem';
import Divider from '@mui/material/Divider';
import Inventory from '@mui/icons-material/Inventory';

const ListRouter = ({
    onItemClick,
}: {
    onItemClick?: () => void;
}): ReactElement => {
    return (
        <>
            <Paper elevation={0}>
                <List aria-label="gestione lotti">
                    <ListItemLink
                        to="/lots"
                        primary="Gestione Lotti"
                        onItemCLick={onItemClick}
                        icon={<Inventory />}
                    />
                </List>
                <Divider />
            </Paper>
        </>
    );
};

export default memo(ListRouter);

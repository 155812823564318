import { memo, ReactElement, SyntheticEvent } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from './alert';
import IconButton from '@mui/material/IconButton';
import { Clear } from '@mui/icons-material';

interface ISucessSnackBar {
    readonly open: boolean;
    readonly message?: string;
    readonly onClose: (event: SyntheticEvent | Event) => void;
}

const ErrorSnackBar = (props: ISucessSnackBar): ReactElement => {
    return (
        <>
            <Snackbar
                open={props.open}
                onClose={props.onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    severity="error"
                    sx={{ width: '100%' }}
                    action={
                        <>
                            <IconButton
                                size="small"
                                color="inherit"
                                aria-label="delete"
                                onClick={props.onClose}
                            >
                                <Clear fontSize="small" />
                            </IconButton>
                        </>
                    }
                >
                    {props.message ?? `Si è verificato un errore. Riprova!`}
                </Alert>
            </Snackbar>
        </>
    );
};

export default memo(ErrorSnackBar);

import Button from '@mui/material/Button';
import { ReactElement, memo, useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import FilterElement from './filterElement';
import {
    Column,
    Filter,
    FilterOperator,
    numberOperators,
    stringOperators,
    Operator,
} from '../../../shared/interfaces/entity/advancedFilter';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useGridApiContext } from '@mui/x-data-grid';
import { primitive } from '../../../shared/type/customType';
import {
    isNotUndefined,
    isUndefined,
} from '../../../shared/utils/interfaceUtils';
import { useFilterContext } from './filterContext';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface IFlterPanelComponent {
    readonly columns: Column[];
    readonly onClosePanel?: () => void;
}

const FilterPanelComponent = (props: IFlterPanelComponent): ReactElement => {
    const [filters, setFilters] = useState<Filter[]>([]);
    const filterContext = useFilterContext();
    const apiRef = useGridApiContext();

    useEffect(() => {
        if (isNotUndefined(filterContext.filters)) {
            setFilters(filterContext.filters);
        }
    }, []);

    const setOperators = (column: Column): FilterOperator[] => {
        switch (column?.type) {
            case 'number':
            case 'date':
                return numberOperators;
            case 'string':
                return stringOperators;
            case 'singleSelect':
                return setSingleSelectOperators(column.options);
            default:
                return [];
        }
    };

    const setSingleSelectOperators = (
        optionValues: primitive[],
    ): FilterOperator[] => {
        if (optionValues.every((o) => typeof o === 'number')) {
            return numberOperators;
        }
        return stringOperators;
    };

    /*** handle events*/

    const onAddFilterClick = (): void => {
        const newFilter: Filter = {};
        setFilters((oldFilter) => [...oldFilter, newFilter]);
    };

    const onConfirmClick = (): void => {
        closePanel(filters);
    };

    const handleDeleteFilter = (id: number): void => {
        const newFilters = filters.filter((_f, index) => index !== id);
        setFilters(newFilters);
        if (newFilters.length === 0) {
            closePanel(newFilters);
        }
    };

    const closePanel = (filters: Filter[]): void => {
        apiRef.current.hideFilterPanel();
        props?.onClosePanel();
        filterContext.setFilters(filters);
    };

    const handleColumnChange = (column: Column, index: number): void => {
        setFilters((oldFilters) =>
            oldFilters.map((oldFilter, i) => {
                if (index === i) {
                    return { ...oldFilter, column };
                }
                return oldFilter;
            }),
        );
    };

    const handleOperatorChange = (operator: Operator, index: number): void => {
        setFilters((oldFilters) =>
            oldFilters.map((oldFilter, i) => {
                if (index === i) {
                    return { ...oldFilter, operator };
                }
                return oldFilter;
            }),
        );
    };

    const handleValueChange = (
        value: primitive | Date,
        index: number,
    ): void => {
        setFilters((oldFilters) =>
            oldFilters.map((oldFilter, i) => {
                if (index === i) {
                    return { ...oldFilter, value };
                }
                return oldFilter;
            }),
        );
    };

    /*** */

    const validateFilter = (): boolean => {
        return filters.find(
            (filter) =>
                isUndefined(filter.column) ||
                isUndefined(filter.operator) ||
                isUndefined(filter.value),
        )
            ? true
            : false;
    };

    return (
        <>
            <Stack>
                {filters.map((filter, index) => (
                    <FilterElement
                        key={index}
                        id={index}
                        filter={filter}
                        columns={props.columns}
                        operators={setOperators(filter.column)}
                        onDelete={handleDeleteFilter}
                        onColumnChange={handleColumnChange}
                        onOperatorChange={handleOperatorChange}
                        onValueChange={handleValueChange}
                    />
                ))}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        startIcon={<AddIcon />}
                        variant="text"
                        onClick={onAddFilterClick}
                    >
                        Aggiungi filtro
                    </Button>
                    {filters.length ? (
                        <Button
                            startIcon={<CheckIcon />}
                            variant="text"
                            className="mainTextButton"
                            onClick={onConfirmClick}
                            disabled={validateFilter()}
                        >
                            Conferma
                        </Button>
                    ) : (
                        <></>
                    )}
                </Box>
            </Stack>
        </>
    );
};

export default memo(FilterPanelComponent);

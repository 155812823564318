import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactElement, SyntheticEvent } from 'react';
import { SelectEntity } from '../../shared/interfaces/entity/utils/selectEntity';

interface IAgencySelectComponent {
    readonly onSelectChange?: (data: SelectEntity) => void;
    readonly required?: boolean;
}

export const AgencySelectComponent = (
    props: IAgencySelectComponent,
): ReactElement => {
    const agencies = [
        { id: 1, label: 'Agenzia 1' },
        { id: 2, label: 'Agenzia 2' },
    ];

    const onAgencySeletect = (value: SelectEntity): void => {
        props.onSelectChange(value);
    };

    return (
        <>
            {' '}
            <Autocomplete
                disablePortal
                id="agency"
                options={agencies}
                renderInput={(params): ReactElement => (
                    <TextField
                        {...params}
                        label="Agenzia"
                        required={props.required}
                    />
                )}
                isOptionEqualToValue={(option, value): boolean =>
                    option.id === value.id
                }
                noOptionsText="nessuna agenzia"
                onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: SelectEntity,
                ): void => onAgencySeletect(value)}
            />
        </>
    );
};

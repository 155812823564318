import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFilterContext } from '../../../components/table/filter/filterContext';
import TablePageComponent from '../../../components/table/tablePage';
import { useDownloadExcel } from '../../../shared/api/excel/download';
import { usePacks } from '../../../shared/api/pack/getAll';
import {
    Filter,
    Operator,
} from '../../../shared/interfaces/entity/advancedFilter';
import { BaseFilter } from '../../../shared/interfaces/entity/baseFilter';
import { Pack } from '../../../shared/interfaces/entity/pack';
import { Pagination } from '../../../shared/interfaces/entity/pagination';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import { isNotUndefined } from '../../../shared/utils/interfaceUtils';
import ErrorSnackBar from '../../../components/utils/errorSnackbar';
import { PACKS_BASE_PATH } from '../../../shared/api/pack/config';

export const PackListComponent = (): ReactElement => {
    const modeContext = useModeContext();
    const params = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [queryOptions, setQueryOptions] = useState<Filter[]>([]);
    const [filter, setFilter] = useState<Maybe<BaseFilter>>(undefined);
    const [download, setDownload] = useState<Maybe<boolean>>(undefined);
    const [open, setOpen] = useState(false);
    const filterContext = useFilterContext();
    const [success, setSuccess, error] = useDownloadExcel(
        `${PACKS_BASE_PATH}/download/excel`,
        filter,
        download,
    );

    const [lotFilter] = useState<Filter[]>([
        {
            column: {
                identifier: 'lot.id',
                name: 'lot',
                type: 'string',
            },
            operator: Operator.EQ,
            value: +params['id'],
        },
    ]);

    const queryParams: { pagination: Pagination; queryOptions: Filter[] } =
        useMemo(
            () => ({
                pagination: {
                    page,
                    pageSize,
                },
                queryOptions,
            }),
            [page, pageSize, queryOptions],
        );

    useEffect(() => {
        setFilter({
            ...filter,
            pagination: queryParams.pagination,
            queryOptions: [...lotFilter, ...queryParams.queryOptions],
        });
    }, [queryParams]);

    useEffect(() => {
        if (isNotUndefined(filterContext.filters)) {
            setQueryOptions(filterContext.filters);
        }
    }, [filterContext.filters]);

    useEffect(() => {
        modeContext.setMode('read');
    }, []);

    useEffect(() => {
        if (isNotUndefined(error) || isNotUndefined(success)) {
            setDownload(undefined);
            setSuccess(undefined);
        }
    }, [error, success]);

    const [packs, total] = usePacks([], filter);

    const handlePageChange = (newPage: number): void => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
    };

    const handleRowClick = (pack: Pack): void => {
        navigate(`/lots/${params['id']}/packs/${pack.id}`);
    };

    const handleAddButton = (): void => {
        navigate(`/lots/${params['id']}/packs`);
    };

    const handleDownloadBUtton = (): void => {
        setDownload(true);
    };
    const handleClose = (): void => {
        setOpen(false);
        modeContext.setMode('read');
    };

    const columns: GridColDef<Pack, string, string>[] = [
        {
            field: 'barcode',
            headerName: 'Codice a barre',
            type: 'string',
            flex: 1,
        },
        {
            field: 'agencyId',
            headerName: 'Agenzia',
            type: 'number',
            flex: 1,
        },
        {
            field: 'address',
            headerName: 'Indirizzo',
            type: 'string',
            flex: 1,
        },
        {
            field: 'cap',
            headerName: 'Cap',
            type: 'number',
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'Città',
            type: 'string',
            flex: 1,
        },
        {
            field: 'province',
            headerName: 'provincia',
            type: 'string',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'string',
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: 'Telefono',
            type: 'string',
            flex: 1,
        },
        {
            field: 'recipient',
            headerName: 'Destinatario',
            type: 'string',
            flex: 1,
        },
        {
            field: 'royalty',
            headerName: 'Royalty cliente',
            type: 'string',
            flex: 1,
        },
        {
            field: 'weight',
            headerName: 'Peso',
            type: 'number',
            flex: 1,
        },
    ];

    return (
        <>
            <TablePageComponent
                data={packs}
                pageTitle="Elenco Pacchetti"
                columnsDef={columns}
                changePage={handlePageChange}
                changePageSize={handlePageSizeChange}
                pagination={queryParams.pagination}
                onRowClick={handleRowClick}
                loading={modeContext.mode({
                    loading: true,
                })}
                rowCount={total}
                onClickAddButton={handleAddButton}
                onClickDownloadButton={handleDownloadBUtton}
                downloadButtonLoading={download}
            ></TablePageComponent>
            {modeContext.mode({
                error: (
                    <>
                        <ErrorSnackBar
                            open={open}
                            onClose={handleClose}
                            message={error}
                        />
                    </>
                ),
            })}
        </>
    );
};

import { BaseFilter as BaseFilterDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { BaseFilter } from '../interfaces/entity/baseFilter';
import { isNotUndefined } from '../utils/interfaceUtils';
import { fromAdvancedFilterEntitiesToDTO } from './advancedFilter';
export const fromBaseFilterToDTOMapper = (
    baseFilter: BaseFilter,
): BaseFilterDTO => {
    return {
        limit: baseFilter?.pagination?.pageSize,
        offset: isNotUndefined(baseFilter?.pagination?.page)
            ? baseFilter?.pagination?.page + 1
            : undefined,
        relations: baseFilter?.relations,
        relationsId: baseFilter?.relationsId,
        queryOptions: fromAdvancedFilterEntitiesToDTO(
            baseFilter?.queryOptions ?? [],
        ),
    } as BaseFilterDTO;
};

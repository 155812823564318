import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

export const PageTitle = ({
    title,
    className,
    sx,
}: {
    title: string;
    className?: string;
    sx?: SxProps;
}): ReactElement => {
    return (
        <>
            <Typography
                variant="h4"
                gutterBottom
                component="div"
                className={`${className}`}
                sx={sx}
            >
                {title}
            </Typography>
        </>
    );
};

import { memo, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PackFormComponent from '../../../components/form/pack/packForm';
import { Pack } from '../../../shared/interfaces/entity/pack';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import SuccessSnackBar from '../../../components/utils/successSnackbar';
import ErrorSnackBar from '../../../components/utils/errorSnackbar';
import { usePack } from '../../../shared/api/pack/get';
import { BaseFilter } from '../../../shared/interfaces/entity/baseFilter';
import { isNotUndefined } from '../../../shared/utils/interfaceUtils';
import { useUpdatePack } from '../../../shared/api/pack/update';

const EditPackComponent = (): ReactElement => {
    const modeContext = useModeContext();
    const params = useParams();
    const [open, setOpen] = useState(false);
    const [filter] = useState<Maybe<BaseFilter>>({
        relations: ['product'],
    });
    const [error, setError] = useState<Maybe<string>>(undefined);
    const [updatedPack, setPack] = useState<Maybe<Pack>>(undefined);

    const [pack, getError] = usePack(params['packId'], filter);
    const [res, updateError] = useUpdatePack(updatedPack);

    useEffect(() => {
        if (isNotUndefined(getError) || isNotUndefined(updateError)) {
            setOpen(true);
            setError(getError ?? updateError);
        }
    }, [getError, updateError]);

    const handleClose = (_event: SyntheticEvent | Event): void => {
        setOpen(false);
        setPack(undefined);
        modeContext.setMode('edit');
    };
    useEffect(() => {
        if (isNotUndefined(res)) {
            setOpen(true);
        }
    }, [res]);

    const onSubmit = (arg: Pack): void => {
        setPack(arg);
    };

    return (
        <>
            <PackFormComponent
                onSubmit={(arg): void => onSubmit(arg)}
                lotId={+params['lotId']}
                pack={pack}
            />
            {modeContext.mode({
                error: (
                    <>
                        <ErrorSnackBar
                            open={open}
                            onClose={handleClose}
                            message={error}
                        />
                    </>
                ),
                success: (
                    <>
                        (
                        <SuccessSnackBar
                            open={open}
                            onClose={handleClose}
                            message={`Il pacchetto è stato aggiornato.`}
                        />
                        )
                    </>
                ),
            })}
        </>
    );
};

export default memo(EditPackComponent);

import { useState } from 'react';
import { Maybe } from './type/customType';

export type Mode =
    | undefined
    | 'edit'
    | 'read'
    | 'loading'
    | 'error'
    | 'success';

export interface ModeValue {
    <T>(probe: Partial<ModeProbe<T>>): Maybe<T>;
}

export interface ModeProbe<T> {
    readonly read: T | (() => T);

    readonly edit: T | (() => T);

    readonly loading: T | (() => T);

    readonly error: T | (() => T);

    readonly success: T | (() => T);
}

export interface ModeSetter {
    (...modes: Mode[]): void;
}

/** NOTA: Fare un refactor con una chain?
 * read and set app mode
 * @returns app mode
 */
export function useMode(): [ModeValue, ModeSetter] {
    const [currentMode, setMode] = useState<Mode>();

    const value: ModeValue = (mode) => {
        if (mode !== undefined && 'edit' in mode && currentMode === 'edit') {
            return mode.edit instanceof Function ? mode.edit() : mode.edit;
        } else if (
            mode !== undefined &&
            'read' in mode &&
            currentMode === 'read'
        ) {
            return mode.read instanceof Function ? mode.read() : mode.read;
        } else if (
            mode !== undefined &&
            'loading' in mode &&
            currentMode === 'loading'
        ) {
            return mode.loading instanceof Function
                ? mode.loading()
                : mode.loading;
        } else if (
            mode !== undefined &&
            'error' in mode &&
            currentMode === 'error'
        ) {
            return mode.error instanceof Function ? mode.error() : mode.error;
        } else if (
            mode !== undefined &&
            'success' in mode &&
            currentMode === 'success'
        ) {
            return mode.success instanceof Function
                ? mode.success()
                : mode.success;
        } else {
            return undefined;
        }
    };

    return [value, setMode];
}

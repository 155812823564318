import { memo, ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import { PageTitle } from '../typography/pageTitle';
import BackButtonComponent from '../utils/backButton';

export interface IPageHeadComponent {
    readonly pageTitle: string;
}

const PageHeadComponent = (props: IPageHeadComponent): ReactElement => {
    return (
        <>
            <Stack direction="row" spacing={2}>
                <BackButtonComponent />
                <PageTitle title={props.pageTitle} />
            </Stack>
        </>
    );
};

export default memo(PageHeadComponent);

import { createTheme } from '@mui/material/styles';
import { itIT as coreItIT } from '@mui/material/locale';
import { itIT } from '@mui/x-data-grid';

let theme = createTheme(
    {
        palette: {
            primary: {
                main: '#eb7303',
            },
            secondary: {
                main: '#d32f2f',
            },
        },
        spacing: 4,
    },
    itIT,
    coreItIT,
);

theme = createTheme(theme, {
    palette: {
        info: {
            main: theme.palette.secondary.main,
        },
    },
});

export default theme;

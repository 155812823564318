import { ProductRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/product_rest';
import { Product } from '../interfaces/entity/product';

export const fromProductRestToEntity = (arg: ProductRest): Product => {
    const product: Product = {
        name: arg?.name,
        description: arg?.description,
        label: arg?.name,
        id: arg?.id,
    };

    return product;
};

export const fromProductsRestToEntities = (args: ProductRest[]): Product[] => {
    return args.map((arg) => fromProductRestToEntity(arg));
};

import {
    Condition as ConditionDTO,
    FindOption as FindOptionDTO,
} from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/advanced_filter';
import { OperatorDTO } from '../interfaces/dto/operatorDTO';
import { Filter, Operator } from '../interfaces/entity/advancedFilter';
import { primitive } from '../type/customType';

export const fromValueDTOToEntity = (
    arg: ConditionDTO,
): Date | primitive | number[] => {
    if (arg.dateValue !== undefined) {
        return new Date(arg.dateValue);
    } else if (arg.numberValue !== undefined) {
        return arg.numberValue;
    } else if (arg.repeatedNumberValue !== undefined) {
        return arg.repeatedNumberValue;
    } else {
        return arg.stringValue;
    }
};

export const fromAdvancedFilterEntityToDTO = (arg: Filter): FindOptionDTO => {
    return {
        key: arg.column.identifier,
        condition: fromConditionEntitytoDTO(arg),
    };
};

export const fromAdvancedFilterEntitiesToDTO = (
    arg: Filter[],
): FindOptionDTO[] => {
    return arg.map((f) => fromAdvancedFilterEntityToDTO(f));
};

/**
 * ATTENZIONE!!: USARE L'ENUM GENERATO DAI PROTO ROMPE SPA, STESSA COSA SUCCEDE CON I METODI FROMPARTIAL
 * @param arg Operator
 * @returns number
 */
export const fromOperatorEntityToDTO = (arg?: Operator): number => {
    switch (arg) {
        case Operator.EQ:
            return OperatorDTO.OPERATOR_EQ;
        case Operator.GT:
            return OperatorDTO.OPERATOR_GT;
        case Operator.LT:
            return OperatorDTO.OPERATOR_LT;
        case Operator.GTE:
            return OperatorDTO.OPERATOR_GTE;
        case Operator.LTE:
            return OperatorDTO.OPERATOR_LTE;
        case Operator.LIKE:
            return OperatorDTO.OPERATOR_LIKE;
        case Operator.IN:
            return OperatorDTO.OPERATOR_IN;
        default:
            return OperatorDTO.OPERATOR_EQ;
    }
};

export const fromConditionEntitytoDTO = (arg: Filter): ConditionDTO => {
    const condition = {
        operator: fromOperatorEntityToDTO(arg.operator),
    } as ConditionDTO;

    if (arg.value instanceof Date) {
        return { ...condition, dateValue: arg.value.toDateString() };
    } else if (typeof arg.value === 'string') {
        return { ...condition, stringValue: arg.value };
    } else if (typeof arg.value === 'number') {
        return { ...condition, numberValue: arg.value };
    } else {
        return { ...condition, repeatedNumberValue: arg.value };
    }
};

import React, { useContext } from 'react';

import { AppContext } from '../shared/provideAppContext';

function Dashboard(): React.ReactElement {
    const { mode } = useContext(AppContext);

    return (
        mode({
            read: <div>READ COMP</div>,
            loading: <div>Loading COMP</div>,
            edit: <div>EDIT COMP</div>,
        }) || <></>
    );
}
export default React.memo(Dashboard);

import { ReactElement, ReactNode } from 'react';
import './fieldset.css';

interface IFieldSetComponent {
    readonly className?: string;
    readonly label: string;
    readonly children?: ReactNode;
}

export const FieldSetComponent = (props: IFieldSetComponent): ReactElement => {
    return (
        <>
            <fieldset className={props.className}>
                <legend>{props.label}</legend>
                {props.children}
            </fieldset>
        </>
    );
};

import { ReactElement, useMemo, forwardRef, memo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';

interface ListItemLinkProps {
    icon?: ReactElement;
    primary: string;
    to: string;
    onItemCLick?: () => void;
}

const ListItemLink = (props: ListItemLinkProps): ReactElement => {
    const { icon, primary, to } = props;

    const renderLink = useMemo(
        () =>
            forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
                function Link(itemProps, ref) {
                    return (
                        <RouterLink
                            to={to}
                            ref={ref}
                            {...itemProps}
                            onClick={props.onItemCLick}
                            role={undefined}
                        />
                    );
                },
            ),
        [to, props.onItemCLick],
    );

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
};

export default memo(ListItemLink);

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseFilter } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { provideConfig } from '../provide_config';
import { IAppContex } from '../provideAppContext';
import { ErrorResponse } from '../interfaces/dto/errorDTO';
import { ApiError } from '../utils/customApiError';
import { isUndefined } from '../utils/interfaceUtils';
import { Maybe } from '../type/customType';

export const Get = async <T, K extends BaseFilter>(
    resource: string,
    token: string,
    modeContext: IAppContex,
    filters?: K,
): Promise<T> => {
    modeContext.setMode('loading');

    return axios({
        ...useAxiosBaseConfig(resource, token),
        method: 'GET',
        params: createQueryParams(filters),
    })
        .then((response: AxiosResponse<T>) => {
            modeContext.setMode('read');
            return response.data;
        })
        .catch((error: AxiosError<ErrorResponse>) => {
            modeContext.setMode('error');
            throw new ApiError(error.response?.data);
        });
};

export const Post = async <T, K>(
    resource: string,
    token: string,
    modeContext: IAppContex,
    body: T,
): Promise<K> => {
    modeContext.setMode('loading');

    return axios({
        method: 'POST',
        ...useAxiosBaseConfig(resource, token),
        data: body,
    })
        .then((response: AxiosResponse<K>) => {
            modeContext.setMode('success');
            return response.data;
        })
        .catch((error: AxiosError<ErrorResponse>) => {
            modeContext.setMode('error');
            throw new ApiError(error.response?.data);
        });
};

export const Put = async <T, K>(
    resource: string,
    token: string,
    modeContext: IAppContex,
    body: T,
): Promise<K> => {
    modeContext.setMode('loading');

    return axios({
        method: 'PUT',
        ...useAxiosBaseConfig(resource, token),
        data: body,
    })
        .then((response: AxiosResponse<K>) => {
            modeContext.setMode('success');
            return response.data;
        })
        .catch((error: AxiosError<ErrorResponse>) => {
            modeContext.setMode('error');
            throw new ApiError(error.response?.data);
        });
};

export const Del = async (
    resource: string,
    token: string,
    modeContext: IAppContex,
): Promise<void> => {
    modeContext.setMode('loading');

    return axios({
        method: 'DELETE',
        ...useAxiosBaseConfig(resource, token),
    })
        .then((response: AxiosResponse<void>) => {
            modeContext.setMode('success');
            return response?.data;
        })
        .catch((error: AxiosError<ErrorResponse>) => {
            modeContext.setMode('error');
            throw new ApiError(error.response?.data);
        });
};

export const Download = async <K extends BaseFilter>(
    resource: string,
    token: string,
    modeContext: IAppContex,
    filters?: K,
    fileName?: string,
): Promise<Blob> => {
    modeContext.setMode('loading');
    const baseConfig = useAxiosBaseConfig(resource, token);
    return axios({
        ...{
            ...baseConfig,
            headers: {
                ...baseConfig.headers,
            },
            responseType: 'arraybuffer',
        },
        method: 'GET',
        params: createQueryParams(filters),
    })
        .then((response: AxiosResponse<Blob>) => {
            modeContext.setMode('read');
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName ??
                    getFileName(response.headers['content-disposition']),
            );
            document.body.appendChild(link);
            link.click();
            return response.data;
        })
        .catch((error: AxiosError<ErrorResponse>) => {
            modeContext.setMode('error');
            throw new ApiError(error.response?.data);
        });
};

const useAxiosBaseConfig = (
    resource: string,
    token: string,
): AxiosRequestConfig => {
    const config = provideConfig();
    //const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null); usato qui va in loop
    return {
        url: `${config.baseApiUrl}${resource}`,
        headers: {
            Authorization: `Bearer  ${token}`,
        },
    } as AxiosRequestConfig;
};

const createQueryParams = <K extends BaseFilter>(
    filters: K,
): URLSearchParams => {
    const params = new URLSearchParams({});
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (isUndefined(value)) {
            return;
        } else if (
            value instanceof Array &&
            (value.every((v) => typeof v === 'number') ||
                value.every((v) => typeof v === 'string'))
        ) {
            (value as string[]).forEach((s) => params.append(`${key}[]`, s));
        } else if (!(value instanceof Array) && typeof value !== 'object') {
            params.append(key, filters[key]);
        } else {
            params.append(key, JSON.stringify(filters[key]));
        }
    });

    return params;
};

const getFileName = (contentDisposition: string): Maybe<string> => {
    const res = contentDisposition.split('filename=');
    return res.length ? res[1] : undefined;
};

import Box from '@mui/material/Box';
import { FC, useState, ReactElement, memo } from 'react';
import { MainAppBar } from './appbar';
import LeftDrawer from './drawer';

const MainNavBar: FC<Record<string, never>> = (): ReactElement => {
    const [open, setOpen] = useState(false);

    const handleOpenDrawer = (): void => {
        setOpen(!open);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <MainAppBar openDrawer={handleOpenDrawer} />
            <LeftDrawer open={open} onClose={handleOpenDrawer} />
        </Box>
    );
};

export default memo(MainNavBar);

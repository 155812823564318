export const exist = <Maybe>(arg: Maybe): boolean => {
    return isNotUndefined(arg);
};
/**
 * check if properties exist on interface
 * @param args T or undefined
 * @returns boolean
 */
export const existAll = <Maybe>(args: Maybe[]): boolean => {
    return args.map((arg) => exist(arg)).find((arg) => arg === false) ?? true;
};

/**
 * check if value is null
 * @param arg T or undefined
 * @returns boolean
 */
export const isNull = <Maybe>(arg: Maybe): boolean => {
    return arg === null;
};

/**
 * check if value is null
 * @param arg T or undefined
 * @returns boolean
 */
export const isNotNull = <Maybe>(arg: Maybe): boolean => {
    return !isNull(arg);
};

/**
 * check if value is undefined
 * @param arg T or undefined
 * @returns boolean
 */
export const isUndefined = <Maybe>(arg: Maybe): boolean => {
    return arg === undefined;
};

/**
 * check if value is undefined
 * @param arg T or undefined
 * @returns boolean
 */
export const isNotUndefined = <Maybe>(arg: Maybe): boolean => {
    return !isUndefined(arg);
};

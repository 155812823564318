import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactElement, memo, SyntheticEvent } from 'react';
import { HUB } from '../../shared/interfaces/entity/hub';
import { SelectEntity } from '../../shared/interfaces/entity/utils/selectEntity';

const HubSelectComponent = ({
    onSelect,
    required,
}: {
    onSelect?: (hub: HUB) => void;
    required?: boolean;
}): ReactElement => {
    const hubs: SelectEntity[] = Object.values(HUB).map((v, i) => {
        return {
            id: i,
            label: v,
        } as SelectEntity;
    });

    const onSelectChange = (value: SelectEntity): void => {
        const key: string = Object.keys(HUB).find(
            (i) => HUB[i] === value.label,
        );
        onSelect(HUB[key]);
    };

    return (
        <>
            <Autocomplete
                disablePortal
                id="hub"
                options={hubs}
                renderInput={(params): ReactElement => (
                    <TextField {...params} label="Hub" required={required} />
                )}
                isOptionEqualToValue={(option, value): boolean =>
                    option.id === value.id
                }
                noOptionsText="nessun Hub"
                onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: SelectEntity,
                ): void => onSelectChange(value)}
            />
        </>
    );
};

export default memo(HubSelectComponent);

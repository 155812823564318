import { CustomerRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/customer_rest';
import { Customer as CustomerProto } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/customer';
import { Customer } from '../interfaces/entity/customer';

export const fromCustomerRestToEntity = (arg: CustomerRest): Customer => {
    const customer: Customer = {
        id: arg?.id,
        label: arg?.name,
        address: arg?.address,
        cap: arg?.cap,
        city: arg?.city,
        name: arg?.name,
        province: arg?.province,
        vatNumber: arg?.vatNumber,
    };
    return customer;
};

export const fromCustomersRestToEntities = (
    arg: CustomerRest[],
): Customer[] => {
    return arg.map((c) => fromCustomerRestToEntity(c));
};

export const fromCustomerEntityToProto = (arg: Customer): CustomerProto => {
    const customer = {
        id: arg?.id,
        address: arg?.address,
        cap: arg?.cap,
        city: arg?.city,
        name: arg?.name,
        province: arg?.province,
        vatNumber: arg?.vatNumber,
    } as CustomerProto;
    return customer;
};

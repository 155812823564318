import { memo, ReactElement, ReactNode, SyntheticEvent } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from './alert';

interface ISucessSnackBar {
    readonly open: boolean;
    readonly message: string;
    readonly onClose: (event?: SyntheticEvent | Event) => void;
    readonly action?: ReactNode;
}

const SHOW_LENGTH = 5000;

const SuccessSnackBar = (props: ISucessSnackBar): ReactElement => {
    setTimeout(() => {
        props.onClose();
    }, SHOW_LENGTH);

    return (
        <>
            <Snackbar
                open={props.open}
                autoHideDuration={SHOW_LENGTH}
                onClose={(event): void => props.onClose(event)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    severity="success"
                    sx={{ width: '100%' }}
                    action={props.action}
                >
                    {props.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default memo(SuccessSnackBar);

import Button from '@mui/material/Button';
import {
    ReactElement,
    memo,
    useState,
    ChangeEvent,
    useRef,
    useEffect,
} from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Maybe } from '../../shared/type/customType';
import { isUndefined } from '../../shared/utils/interfaceUtils';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

interface IUploadButton {
    readonly uploadedFileIcon?: ReactElement;
    readonly disabled?: boolean;
    readonly className?: string;
    readonly onFileChange?: (file: Maybe<File>) => void;
    readonly accept: string;
}

const UploadButton = (props: IUploadButton): ReactElement => {
    const [file, setFile] = useState<Maybe<File>>(undefined);
    const inputFIle = useRef(null);

    useEffect(() => {
        props.onFileChange(file);
    }, [file]);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFile(e.target.files[0]);
    };

    const setIcon = (): ReactElement => {
        return isUndefined(file) ? <FileUploadIcon /> : setCustomIcon();
    };

    const setCustomIcon = (): ReactElement => {
        return isUndefined(props.uploadedFileIcon) ? (
            <AttachFileIcon />
        ) : (
            props.uploadedFileIcon
        );
    };

    const clearFile = (): void => {
        inputFIle.current.value = null;
        setFile(undefined);
        props.onFileChange(undefined);
    };

    return (
        <>
            <Button
                component="label"
                disabled={props.disabled}
                className={props.className}
                startIcon={setIcon()}
            >
                {isUndefined(file) ? 'Carica file' : `${file.name}`}
                <input
                    hidden
                    accept={props.accept}
                    multiple
                    type="file"
                    ref={inputFIle}
                    onChange={(e): void => onFileChange(e)}
                />
            </Button>
            {isUndefined(file) ? (
                <></>
            ) : (
                <IconButton aria-label="delete" onClick={clearFile}>
                    <ClearIcon color="primary" />
                </IconButton>
            )}
        </>
    );
};

export default memo(UploadButton);

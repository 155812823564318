import { ReactElement, memo, useState, useMemo } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridCallbackDetails,
    GridToolbarColumnsButton,
    GridColDef,
    GridRowParams,
    GridSelectionModel,
    itIT,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import './table.css';
import { Pagination } from '../../shared/interfaces/entity/pagination';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FilterPanelComponent from './filter/filterPanel';
import PageHeadComponent from '../pages/head';
import { Column } from '../../shared/interfaces/entity/advancedFilter';
import { primitive } from '../../shared/type/customType';
import GridFilterButton from './filter/filterButton';
import DownloadButton from './download/downloadButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface ITablePageComponent<T, K, Y> {
    readonly pageTitle: string;
    readonly data: T[];
    readonly columnsDef: GridColDef<T, K, Y>[];
    readonly pagination: Pagination;
    readonly rowCount: number;
    readonly changePage: (page: number) => void;
    readonly changePageSize: (pageSize: number) => void;
    readonly onRowClick: (data: T) => void;
    readonly loading?: boolean;
    readonly onClickAddButton?: () => void;
    readonly onClickDeleteButton?: () => void;
    readonly onClickDownloadButton?: () => void;
    readonly downloadButtonLoading?: boolean;
}
/*
 * Generic table componet
 * @param props ITablePageComponent (T is entity type, K is valueGetter return type, Y = K)
 * @returns GridTable
 */
const TablePageComponent = <T, K, Y>(
    props: ITablePageComponent<T, K, Y>,
): ReactElement => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showFitlers, setShowFilters] = useState(false);

    const onRowSelected = (
        selectionModel: GridSelectionModel,
        _details: GridCallbackDetails,
    ): void => {
        setSelectedRows(selectionModel as number[]);
    };

    const columnsFilter: Column[] = useMemo(
        () =>
            props.columnsDef.map((column) => {
                return {
                    identifier: column.field,
                    name: column.headerName,
                    type: column.type,
                    options: column.valueOptions as primitive[],
                };
            }),
        [props.columnsDef],
    );

    const handleShowFiltersPanel = (): void => {
        setShowFilters(!showFitlers);
    };

    return (
        <>
            <Box className="MainPageBox">
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <PageHeadComponent pageTitle={props.pageTitle} />

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        className="boxButton"
                    >
                        <Button
                            variant="contained"
                            className="mainButton"
                            disabled={selectedRows.length > 0}
                            onClick={props.onClickAddButton}
                            startIcon={<AddIcon />}
                        >
                            Nuovo
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            disabled={selectedRows.length === 0}
                        >
                            Elimina
                        </Button>
                    </Stack>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            disableSelectionOnClick
                            autoHeight
                            localeText={
                                itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            rows={props.data}
                            rowCount={props.rowCount}
                            columns={props.columnsDef}
                            rowsPerPageOptions={[5, 10, 15]}
                            checkboxSelection
                            loading={props.loading}
                            page={props.pagination.page}
                            pageSize={props.pagination.pageSize}
                            paginationMode="server"
                            filterMode="server"
                            onSelectionModelChange={(
                                selectionModel: GridSelectionModel,
                                details: GridCallbackDetails,
                            ): void => onRowSelected(selectionModel, details)}
                            onPageChange={(newPage): void =>
                                props.changePage(newPage)
                            }
                            onPageSizeChange={(newPageSize): void =>
                                props.changePageSize(newPageSize)
                            }
                            onRowClick={(prams: GridRowParams<T>): void =>
                                props.onRowClick(prams.row)
                            }
                            getRowClassName={(): string => `pencil`}
                            components={{
                                Toolbar: () => (
                                    <GridToolbarContainer>
                                        <GridToolbarColumnsButton />
                                        <GridFilterButton
                                            openPanel={showFitlers}
                                            onClickFiltersButton={
                                                handleShowFiltersPanel
                                            }
                                        />
                                        <GridToolbarDensitySelector />
                                        {props?.onClickDownloadButton && (
                                            <DownloadButton
                                                onClick={
                                                    props.onClickDownloadButton
                                                }
                                                loading={
                                                    props.downloadButtonLoading
                                                }
                                            />
                                        )}
                                    </GridToolbarContainer>
                                ),
                                FilterPanel: () => (
                                    <FilterPanelComponent
                                        columns={columnsFilter}
                                        onClosePanel={handleShowFiltersPanel}
                                    />
                                ),
                            }}
                        />
                    </div>
                </Stack>
            </Box>
        </>
    );
};

export default memo(TablePageComponent);

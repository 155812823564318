import { ReactElement } from 'react';
import { useRoutes } from 'react-router-dom';
import Dashboard from '../dashboard/dashboard';
import { LotListComponent } from '../pages/lot/list/lotList';
import { LotComponent } from '../pages/lot/entity/lot';
import { PackListComponent } from '../pages/pack/list/packList';
import { TestPage2 } from '../testpage/test_page2';
import NewPackComponent from '../pages/pack/entity/newPack';
import EditPackComponent from '../pages/pack/entity/editPack';
import { FilterContextProvider } from '../components/table/filter/filterContext';

export const CustomRoutes = (): ReactElement => {
    return useRoutes([
        {
            path: '/',
            element: <Dashboard />,
        },
        {
            path: 'lots',
            element: (
                <FilterContextProvider>
                    <LotListComponent />
                </FilterContextProvider>
            ),
        },
        {
            path: 'lots/new',
            element: <LotComponent />,
        },
        {
            path: 'lots/:id/lot',
            element: (
                <FilterContextProvider>
                    <PackListComponent />
                </FilterContextProvider>
            ),
        },
        {
            path: 'lots/:lotId/packs/:packId',
            element: <EditPackComponent />,
        },
        {
            path: 'lots/:lotId/packs',
            element: <NewPackComponent />,
        },
        {
            path: 'page2',
            element: <TestPage2 />,
        },
    ]);
};

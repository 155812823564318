import { FC, ReactElement } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NotificationComponent from './notification';
import ProfileMenu from './profile_menu';

interface IMainAppbar {
    openDrawer(): void;
}

export const MainAppBar: FC<IMainAppbar> = (
    props: IMainAppbar,
): ReactElement => {
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={props.openDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        Fulmine
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <NotificationComponent notificationCount={0} />
                        <ProfileMenu />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    memo,
    ReactElement,
    SyntheticEvent,
    useState,
    ChangeEvent,
    FormEvent,
    useEffect,
    useMemo,
} from 'react';
import { useProducts } from '../../../shared/api/product/getAll';
import { Pack } from '../../../shared/interfaces/entity/pack';
import { Product } from '../../../shared/interfaces/entity/product';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import { FieldSetComponent } from '../../utils/fielldset';
import './packForm.css';
import SaveIcon from '@mui/icons-material/Save';
import PageHeadComponent from '../../pages/head';
import { existAll } from '../../../shared/utils/interfaceUtils';
import { removeEmpty, setDotFloat } from '../../../shared/utils/formUtils';

interface IPackFormComponent {
    readonly onSubmit?: (data: Pack) => void;
    readonly lotId: number;
    readonly pack?: Pack;
}

const PackFormComponent = (props: IPackFormComponent): ReactElement => {
    const [pack, setPack] = useState<Maybe<Pack>>(undefined);
    const modeContext = useModeContext();
    const [products] = useProducts([]);

    const currentPack = useMemo(() => {
        return pack;
    }, [pack]);

    useEffect(() => {
        setPack({
            ...props.pack,
            lot: {
                ...props?.pack?.lot,
                id: props.lotId,
            },
        });
    }, [props.pack]);

    /**handle set properties */

    const onProductSelect = (product: Product): void => {
        setPack({ ...pack, product });
    };

    const onBarcodeChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, barcode: event.target.value });
    };

    const onWeigthChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, weight: setDotFloat(event.target.value) });
    };

    const onRecipientChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, recipient: event.target.value });
    };

    const onAddressChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, address: event.target.value });
    };

    const onCityChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, city: event.target.value });
    };

    const onProvinceChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, province: event.target.value });
    };

    const onCapChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, cap: event.target.value });
    };

    const onPhoneNumberChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, phoneNumber: event.target.value });
    };

    const onEmailChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, email: event.target.value });
    };

    const onRoyaltyChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => {
        setPack({ ...pack, royalty: event.target.value });
    };

    const formIsValid = (): boolean => {
        return existAll([pack?.product, pack?.barcode]);
    };

    /*****/

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        props.onSubmit(removeEmpty(pack));
    };

    return (
        <>
            <PageHeadComponent pageTitle="Nuovo Pacchetto" />
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: 2 }}
                onSubmit={(e: FormEvent<HTMLFormElement>): void =>
                    handleSubmit(e)
                }
            >
                <FieldSetComponent label="Pacco" className="paddingBottom">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Autocomplete
                            disablePortal
                            id="product"
                            options={products}
                            className="productInput"
                            value={currentPack?.product ?? ''}
                            renderInput={(params): ReactElement => (
                                <TextField
                                    {...params}
                                    label="Prodotti"
                                    required={true}
                                    variant="standard"
                                />
                            )}
                            noOptionsText="nessun prodotto"
                            onChange={(
                                event: SyntheticEvent<Element, Event>,
                                value: Product,
                            ): void => onProductSelect(value)}
                            sx={{ mr: 4 }}
                        />
                        <TextField
                            id="barcode"
                            label="Barcode"
                            variant="standard"
                            required
                            sx={{ mr: 4 }}
                            value={currentPack?.barcode ?? ''}
                            onChange={(e): void => onBarcodeChange(e)}
                        />

                        <TextField
                            id="weight"
                            label="Peso"
                            type="number"
                            inputProps={{
                                inputMode: 'decimal',
                                pattern: '[0-9]*',
                            }}
                            variant="standard"
                            value={currentPack?.weight ?? ''}
                            onChange={(e): void => onWeigthChange(e)}
                        />
                    </Box>
                </FieldSetComponent>
                <FieldSetComponent
                    label="Destinatario"
                    className="fiedsetMarginTop paddingBottom"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <TextField
                            id="recipient"
                            label="Nome e Cognome"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.recipient ?? ''}
                            onChange={(e): void => onRecipientChange(e)}
                        />
                        <TextField
                            id="address"
                            label="Indirizzo"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.address ?? ''}
                            onChange={(e): void => onAddressChange(e)}
                        />

                        <TextField
                            id="city"
                            label="Città"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.city ?? ''}
                            onChange={(e): void => onCityChange(e)}
                        />

                        <TextField
                            id="province"
                            label="Provincia"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.province ?? ''}
                            onChange={(e): void => onProvinceChange(e)}
                        />
                        <TextField
                            id="cap"
                            label="Cap"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.cap ?? ''}
                            onChange={(e): void => onCapChange(e)}
                        />
                        <TextField
                            id="phoneNUmber"
                            label="Numero di telefono"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.phoneNumber ?? ''}
                            onChange={(e): void => onPhoneNumberChange(e)}
                        />
                        <TextField
                            id="email"
                            label="Email"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={currentPack?.email ?? ''}
                            onChange={(e): void => onEmailChange(e)}
                        />
                    </Box>
                </FieldSetComponent>
                <FieldSetComponent
                    label="Altro"
                    className="fiedsetMarginTop paddingBottom"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <TextField
                            id="royalty"
                            label="Royalty cliente"
                            variant="standard"
                            sx={{ mr: 4 }}
                            value={pack?.royalty ?? ''}
                            onChange={(e): void => onRoyaltyChange(e)}
                        />
                    </Box>
                </FieldSetComponent>
                <LoadingButton
                    loading={modeContext.mode({ loading: true })}
                    type="submit"
                    variant="contained"
                    className="submitButton mainButton"
                    startIcon={<SaveIcon />}
                    sx={{ mt: 8 }}
                    disabled={!formIsValid()}
                >
                    Salva
                </LoadingButton>
            </Box>
        </>
    );
};

export default memo(PackFormComponent);

import { Button } from '@mui/material';
import { memo, ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PackFormComponent from '../../../components/form/pack/packForm';
import { useCreatePack } from '../../../shared/api/pack/create';
import { Pack } from '../../../shared/interfaces/entity/pack';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import { isNotUndefined } from '../../../shared/utils/interfaceUtils';
import SuccessSnackBar from '../../../components/utils/successSnackbar';
import ErrorSnackBar from '../../../components/utils/errorSnackbar';

const NewPackComponent = (): ReactElement => {
    const modeContext = useModeContext();
    const params = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pack, setPack] = useState<Maybe<Pack>>(undefined);
    const [createdPack, error] = useCreatePack(pack);

    useEffect(() => {
        if (isNotUndefined(error) || isNotUndefined(createdPack)) {
            setOpen(true);
            setPack(undefined);
        }
    }, [error, createdPack]);

    useEffect(() => {
        modeContext.setMode('read');
    }, []);

    const handleClose = (event: SyntheticEvent | Event): void => {
        setOpen(false);
        modeContext.setMode('read');
    };

    const goToResource = (): void => {
        navigate(`lots/${params['lotId']}/packs/:packId/${createdPack.id}`);
    };

    const onSubmit = (arg: Pack): void => {
        setPack(arg);
    };

    return (
        <>
            <PackFormComponent
                onSubmit={(arg): void => onSubmit(arg)}
                lotId={+params['lotId']}
            />
            {modeContext.mode({
                error: (
                    <>
                        <ErrorSnackBar
                            open={open}
                            onClose={handleClose}
                            message={error}
                        />
                    </>
                ),
                success: (
                    <>
                        <SuccessSnackBar
                            open={open}
                            onClose={handleClose}
                            message={`Il pacchetto è stato creato.`}
                            action={
                                <>
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={goToResource}
                                    >
                                        Visualizza
                                    </Button>
                                </>
                            }
                        />
                    </>
                ),
            })}
        </>
    );
};

export default memo(NewPackComponent);

import { Box, LinearProgress } from '@mui/material';
import React from 'react';

import { Unauthorized } from '../../pages/unauthorized/unauthorized';
import { useAuthState } from './provideAuthState';

export const RequireAuth = ({
    children,
}: {
    children: React.ReactElement;
}): React.ReactElement => {
    const [user, isLogging] = useAuthState();

    if (isLogging) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (!user && !isLogging) {
        //return <Navigate to="/unauthorized" state={{ from: location }} />;
        return (
            <>
                <Unauthorized />
            </>
        );
    }

    return children;
};

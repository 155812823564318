import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { Mode, ModeValue, useMode } from './mode';

/**
 * App context interface
 */
export interface IAppContex {
    mode: ModeValue;
    setMode: (mode: Mode) => void;
}

export const AppContext = createContext<IAppContex>({
    mode: undefined,
    setMode: (_mode: Mode): void => {
        throw new Error(`method not implemented`);
    },
});

export const useModeContext = (): IAppContex => {
    return useContext(AppContext);
};

/**
 * AppContext provder
 * @param param0  child ements
 * @returns ReactElement
 */
export const AppContextProvider = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const [mode, setMode] = useMode();

    return (
        <AppContext.Provider
            value={{
                mode,
                setMode,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

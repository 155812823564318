import { useEffect, useState } from 'react';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import {
    MutatePackRest,
    PackRest,
} from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/pack_rest';

import { Put } from '../client';
import { Pack } from '../../interfaces/entity/pack';
import {
    fromPackDTOToEntitty,
    fromPackEntityToMutate,
} from '../../mapper/packMapper';
import { useModeContext } from '../../provideAppContext';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { Nullable } from '../../type/customType';
import { PACKS_BASE_PATH } from './config';

export const useUpdatePack = (pack: Pack): [Pack, string] => {
    const [data, setData] = useState<Pack>(undefined);
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const modeContext = useModeContext();
    const [error, setError] = useState<Nullable<string>>(null);
    useEffect(() => {
        if (isNotUndefined(pack)) {
            const res = Put<MutatePackRest, PackRest>(
                `${PACKS_BASE_PATH}${pack.id}`,
                token,
                modeContext,
                fromPackEntityToMutate(pack),
            );
            res.then((body) => {
                const pack = fromPackDTOToEntitty(body);
                setData(pack);
            }).catch((err) => {
                setError(err.getMessage());
                setData(undefined);
            });
        } else {
            setError(null);
            setData(undefined);
        }
    }, [pack]);

    return [data, error];
};

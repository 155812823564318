import Button from '@mui/material/Button';
import { memo, ReactElement, useEffect } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import Badge from '@mui/material/Badge';
import { useFilterContext } from './filterContext';

interface IGridFilterButton {
    readonly openPanel?: boolean;
    readonly onClickFiltersButton?: () => void;
}

const GridFilterButton = (props: IGridFilterButton): ReactElement => {
    const apiRef = useGridApiContext();
    const filterContext = useFilterContext();

    useEffect(() => {
        showFilterPanel(props.openPanel);
    }, [props.openPanel]);

    const showFilterPanel = (open: boolean): void => {
        open === true
            ? apiRef.current.showFilterPanel()
            : apiRef.current.hideFilterPanel();
    };

    const handleOnFilterButtonClick = (): void => {
        props.onClickFiltersButton();
    };

    return (
        <>
            <Button
                startIcon={
                    <Badge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        color="secondary"
                        badgeContent={filterContext.filters?.length}
                        showZero={false}
                    >
                        <FilterListIcon />
                    </Badge>
                }
                size="small"
                variant="text"
                onClick={handleOnFilterButtonClick}
            >
                Filtri
            </Button>
        </>
    );
};

export default memo(GridFilterButton);

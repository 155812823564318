// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/pencil.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainPageBox {\n    width: 100%;\n    height: 100%;\n}\n\n\n.boxButton {\n    width: 100%;\n}\n\n.pencil {\n    cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), auto;\n}", "",{"version":3,"sources":["webpack://./src/components/table/table.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,qDAAuC;AAC3C","sourcesContent":[".MainPageBox {\n    width: 100%;\n    height: 100%;\n}\n\n\n.boxButton {\n    width: 100%;\n}\n\n.pencil {\n    cursor: url(\"/assets/pencil.svg\"), auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from 'react';
import { Filter } from '../../../shared/interfaces/entity/advancedFilter';
import { Maybe, Setter } from '../../../shared/type/customType';

/**
 * Filter context interface
 */
export interface IFilterContex {
    filters: Maybe<Filter[]>;
    setFilters: (filters: Filter[]) => void;
}

export interface CurrentFilter {
    key: string;
    filters: Maybe<Filter[]>;
}

export const FilterContext = createContext<IFilterContex>({
    filters: undefined,
    setFilters: (_filters: Filter[]): void => {
        throw new Error(`method not implemented`);
    },
});

export const useFilterContext = (): IFilterContex => {
    return useContext(FilterContext);
};

const useFilters = (
    defaultValue: Maybe<Filter[]>,
): [Filter[], Setter<Filter[]>] => {
    const [filters, setFilters] = useState<Filter[]>(defaultValue);
    return [filters, setFilters];
};

/**
 * FilterContext provder
 * @param param0  child ements
 * @returns ReactElement
 */
export const FilterContextProvider = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const [filters, setFilters] = useFilters(undefined);

    return (
        <FilterContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

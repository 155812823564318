import { memo, ReactElement, ReactNode, ChangeEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {
    Operator,
    FilterOperator,
    Column,
    Filter,
} from '../../../shared/interfaces/entity/advancedFilter';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DateTimeComponent from '../../input/dateTime';
import { primitive } from '../../../shared/type/customType';

interface IFilterElement {
    readonly columns: Column[];
    readonly operators: FilterOperator[];
    readonly id: number;
    readonly filter?: Filter;
    readonly onColumnChange?: (column: Column, id: number) => void;
    readonly onOperatorChange?: (operator: Operator, id: number) => void;
    readonly onValueChange?: (valie: primitive | Date, id: number) => void;
    readonly onDelete?: (id: number) => void;
}

const FilterElement = (props: IFilterElement): ReactElement => {
    const handleColumnChange = (event: SelectChangeEvent): void => {
        const selectedColumn = props.columns.find(
            (c) => c.identifier === event.target.value,
        );
        props.onColumnChange(selectedColumn, props.id);
    };

    const handleOperatorChange = (event: SelectChangeEvent<Operator>): void => {
        const selectedOperator = props.operators.find(
            (o) => o.identifier === (event.target.value as Operator),
        );
        props.onOperatorChange(selectedOperator.identifier, props.id);
    };

    const onDeleteClick = (): void => {
        props.onDelete(props.id);
    };

    /*****/

    const renderInputValue = (): ReactNode => {
        switch (props.filter.column?.type) {
            case 'number':
                return (
                    <FormControl size="small">
                        <TextField
                            id="value"
                            label="Valore"
                            variant="outlined"
                            type="number"
                            size="small"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            value={props.filter.value ?? ''}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ): void => {
                                props.onValueChange(
                                    e.target.valueAsNumber,
                                    props.id,
                                );
                            }}
                            autoComplete="off"
                        />
                    </FormControl>
                );

            case 'date':
                return (
                    <DateTimeComponent
                        label="Value"
                        onDateChange={(val): void =>
                            props.onValueChange(val, props.id)
                        }
                        value={props.filter.value as Date}
                        size="small"
                    />
                );
            case 'singleSelect':
                return (
                    <FormControl sx={{ m: 1, width: 248 }} size="small">
                        <InputLabel id="value-label">Valore</InputLabel>

                        <Select
                            labelId="value"
                            id="value"
                            value={props.filter.value ?? ''}
                            label="Colonne"
                            onChange={(e): void =>
                                props.onValueChange(e.target.value, props.id)
                            }
                        >
                            {props.filter.column.options.map((c, index) => (
                                <MenuItem value={c} key={index}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'string':
            default:
                return (
                    <FormControl size="small">
                        <TextField
                            id="value"
                            label="Valore"
                            variant="outlined"
                            type="text"
                            size="small"
                            value={props.filter.value ?? ''}
                            onChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ): void => {
                                props.onValueChange(e.target.value, props.id);
                            }}
                            autoComplete="off"
                        />
                    </FormControl>
                );
        }
    };

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                m={2}
            >
                <IconButton aria-label="delete" onClick={onDeleteClick}>
                    <CloseIcon color="error" />
                </IconButton>
                <FormControl sx={{ m: 1, width: 120 }} size="small">
                    <InputLabel id="columns-label">Colonne</InputLabel>

                    <Select
                        labelId="columns"
                        id="column"
                        value={props.filter?.column?.identifier ?? ''}
                        label="Colonne"
                        onChange={handleColumnChange}
                    >
                        {props.columns.map((c, index) => (
                            <MenuItem value={c.identifier} key={index}>
                                {c.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 120 }} size="small">
                    <InputLabel id="operators-label">Operatore</InputLabel>

                    <Select
                        labelId="operators"
                        id="operator"
                        value={props.filter.operator ?? ''}
                        label="Operatori"
                        onChange={handleOperatorChange}
                    >
                        {props.operators.map((o, index) => (
                            <MenuItem value={o.identifier} key={index}>
                                {o.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {renderInputValue()}
            </Stack>
        </>
    );
};

export default memo(FilterElement);

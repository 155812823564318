import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { LotFormComponent } from '../../../components/form/lot/lotForm';
import SuccessSnackBar from '../../../components/utils/successSnackbar';
import ErrorSnackBar from '../../../components/utils/errorSnackbar';
import { useCreateLot } from '../../../shared/api/lot/create';
import { Lot } from '../../../shared/interfaces/entity/lot';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import {
    isNotNull,
    isNotUndefined,
} from '../../../shared/utils/interfaceUtils';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export const LotComponent = (): ReactElement => {
    const modeContext = useModeContext();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [lot, setLot] = useState<Maybe<Lot>>(undefined);
    const [createdLot, error] = useCreateLot(lot);

    useEffect(() => {
        if (isNotNull(error) || isNotUndefined(createdLot)) {
            setOpen(true);
            setLot(undefined);
        }
    }, [error, createdLot]);

    const handleClose = (event: SyntheticEvent | Event): void => {
        setOpen(false);
        modeContext.setMode('read');
        setLot(undefined);
    };

    const goToResource = (): void => {
        navigate(`/lots/${createdLot.id}/lot`);
    };

    const onSubmit = (arg: Lot): void => {
        setLot(arg);
    };

    return (
        <>
            <LotFormComponent onSubmit={(arg): void => onSubmit(arg)} />

            {modeContext.mode({
                error: (
                    <>
                        <ErrorSnackBar
                            open={open}
                            onClose={handleClose}
                            message={error}
                        />
                    </>
                ),
                success: (
                    <>
                        <SuccessSnackBar
                            open={open}
                            onClose={handleClose}
                            message={`Il Lotto è stato creato.`}
                            action={
                                <>
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={goToResource}
                                    >
                                        Visualizza
                                    </Button>
                                </>
                            }
                        />
                    </>
                ),
            })}
        </>
    );
};

import { useEffect, useState } from 'react';
import { BaseFilter } from '../../interfaces/entity/baseFilter';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import { useModeContext } from '../../provideAppContext';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { BaseFilter as BaseFilterDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { Download } from '../client';
import { Maybe, Nullable, Setter } from '../../type/customType';
import { fromBaseFilterToDTOMapper } from '../../mapper/baseFilterMapper';

export const useDownloadExcel = (
    path: string,
    defaultFilters?: BaseFilter,
    startDownload?: Maybe<boolean>,
): [boolean, Setter<boolean>, string] => {
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const modeContext = useModeContext();
    const [error, setError] = useState<Nullable<string>>(null);
    const [success, setSuccess] = useState<Maybe<boolean>>(undefined);
    useEffect(() => {
        if (isNotUndefined(defaultFilters) && isNotUndefined(startDownload)) {
            const res = Download<BaseFilterDTO>(
                path,
                token,
                modeContext,
                fromBaseFilterToDTOMapper({
                    queryOptions: defaultFilters.queryOptions,
                }),
            );
            res.then((body) => {
                setSuccess(true);
            }).catch((err) => {
                setError(err.getMessage());
                setSuccess(false);
            });
        }
    }, [defaultFilters, startDownload]);
    return [success, setSuccess, error];
};

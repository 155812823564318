import { memo, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IBackButtonComponent {
    readonly className?: string;
}

const BackButtonComponent = (props: IBackButtonComponent): ReactElement => {
    const navigate = useNavigate();
    return (
        <>
            <IconButton
                aria-label="delete"
                color="primary"
                onClick={(): void => navigate(-1)}
                className={props.className}
            >
                <ArrowBackIcon />
            </IconButton>
        </>
    );
};

export default memo(BackButtonComponent);

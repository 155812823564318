import { useEffect, useState } from 'react';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import { CustomerRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/customer_rest';

import { Get } from '../client';
import { Customer } from '../../interfaces/entity/customer';
import { BaseFilter } from '../../interfaces/entity/baseFilter';
import { useModeContext } from '../../provideAppContext';
import { BaseFilter as BaseFilterDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { fromCustomersRestToEntities } from '../../mapper/customerMapper';
import { fromBaseFilterToDTOMapper } from '../../mapper/baseFilterMapper';
import { All } from '../../interfaces/entity/allEntity';
import { CUSTOMERS_BASE_PATH } from './config';

export const useCustomers = (
    defaultValue: Customer[],
    defaultFilters?: BaseFilter,
): [customers: Customer[], total: number] => {
    const [data, setData] = useState<Customer[]>(defaultValue);
    const [total, setTotal] = useState<number>(0);
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const modeContext = useModeContext();
    useEffect(() => {
        const res = Get<All<CustomerRest>, BaseFilterDTO>(
            CUSTOMERS_BASE_PATH,
            token,
            modeContext,
            fromBaseFilterToDTOMapper(defaultFilters),
        );
        res.then((body) => {
            const customers = fromCustomersRestToEntities(body.data);
            setData(customers);
            setTotal(body.total);
        }).catch((err) => {
            setData([]);
            setTotal(0);
        });
    }, [defaultFilters]);

    return [data, total];
};

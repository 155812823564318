import { Box } from '@mui/material';
import { ReactElement } from 'react';

export const Unauthorized = (): ReactElement => {
    return (
        <>
            <Box>Session scaduta</Box>
        </>
    );
};

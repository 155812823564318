import { ReactElement, useMemo, useState, useEffect } from 'react';
import { useLots } from '../../../shared/api/lot/getAll';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Lot } from '../../../shared/interfaces/entity/lot';
import './lot.css';
import TablePageComponent from '../../../components/table/tablePage';
import { BaseFilter } from '../../../shared/interfaces/entity/baseFilter';
import { Pagination } from '../../../shared/interfaces/entity/pagination';
import { useNavigate } from 'react-router-dom';
import { useModeContext } from '../../../shared/provideAppContext';
import { Maybe } from '../../../shared/type/customType';
import { Filter } from '../../../shared/interfaces/entity/advancedFilter';
import ErrorSnackBar from '../../../components/utils/errorSnackbar';
import { isNotUndefined } from '../../../shared/utils/interfaceUtils';
import { HUB } from '../../../shared/interfaces/entity/hub';
import { useFilterContext } from '../../../components/table/filter/filterContext';
import { useDownloadExcel } from '../../../shared/api/excel/download';
import { LOTS_BASE_PATH } from '../../../shared/api/lot/config';

export const LotListComponent = (): ReactElement => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [queryOptions, setQueryOptions] = useState<Filter[]>([]);
    const modeContext = useModeContext();
    const [filter, setFilter] = useState<Maybe<BaseFilter>>(undefined);
    const filterContext = useFilterContext();
    const [download, setDownload] = useState<Maybe<boolean>>(undefined);
    const [open, setOpen] = useState(false);
    const [success, setSuccess, error] = useDownloadExcel(
        `${LOTS_BASE_PATH}/download/excel`,
        filter,
        download,
    );

    const params: { pagination: Pagination; queryOptions: Filter[] } = useMemo(
        () => ({
            pagination: {
                page,
                pageSize,
            },
            queryOptions,
        }),
        [page, pageSize, queryOptions],
    );

    useEffect(() => {
        setFilter({
            ...filter,
            pagination: params.pagination,
            queryOptions: params.queryOptions,
        });
    }, [params]);

    useEffect(() => {
        if (isNotUndefined(filterContext.filters)) {
            setQueryOptions(filterContext.filters);
        }
    }, [filterContext.filters]);

    useEffect(() => {
        modeContext.setMode('read');
    }, []);

    useEffect(() => {
        if (isNotUndefined(error) || isNotUndefined(success)) {
            setDownload(undefined);
            setSuccess(undefined);
        }
    }, [error, success]);

    const [lots, total] = useLots([], filter);

    const columns: GridColDef<Lot, string, string>[] = [
        {
            field: 'agencyId',
            headerName: 'Agenzia',
            type: 'number',
            flex: 1,
        },
        {
            field: 'distinctDate',
            headerName: 'Data Documento',
            flex: 1,
            type: 'date',
        },
        {
            field: 'firstArrivalBranch',
            headerName: 'Hub di arrivo',
            description: 'Esempio descrizione',
            sortable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams<Lot, Lot>): string =>
                params.row.firstArrivalBranch.toString(),
            type: 'singleSelect',
            valueOptions: Object.values(HUB),
        },
    ];

    const handlePageChange = (newPage: number): void => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
    };

    const handleRowClick = (lot: Lot): void => {
        navigate(`/lots/${lot.id}/lot`);
    };

    const handleAddNewLot = (): void => {
        navigate(`/lots/new`);
    };
    const handleDownloadBUtton = (): void => {
        setDownload(true);
    };

    const handleClose = (): void => {
        setOpen(false);
        modeContext.setMode('read');
    };

    return (
        <>
            <TablePageComponent
                data={lots}
                pageTitle="Gestione lotti"
                columnsDef={columns}
                changePage={handlePageChange}
                changePageSize={handlePageSizeChange}
                pagination={params.pagination}
                loading={modeContext.mode({
                    loading: true,
                })}
                rowCount={total}
                onRowClick={handleRowClick}
                onClickAddButton={handleAddNewLot}
                onClickDownloadButton={handleDownloadBUtton}
                downloadButtonLoading={download}
            ></TablePageComponent>
            {modeContext.mode({
                error: (
                    <>
                        <ErrorSnackBar
                            open={open}
                            onClose={handleClose}
                            message={error}
                        />
                    </>
                ),
            })}
        </>
    );
};

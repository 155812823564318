import LoadingButton from '@mui/lab/LoadingButton';
import { memo, ReactElement } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface IDownloadButton {
    readonly disabled?: boolean;
    readonly loading?: boolean;
    readonly onClick?: () => void;
}

const DownloadButton = (props: IDownloadButton): ReactElement => {
    return (
        <>
            <LoadingButton
                loading={props.loading}
                type="submit"
                variant="text"
                startIcon={<FileDownloadIcon />}
                disabled={props.disabled}
                size="small"
                onClick={props.onClick}
            >
                Download
            </LoadingButton>
        </>
    );
};

export default memo(DownloadButton);

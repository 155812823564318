import { HUB } from '../interfaces/entity/hub';

export const fromHubDtoToEntity = (hub: number): HUB => {
    switch (hub) {
        case 1:
            return HUB.NATIONAL;
        case 2:
            return HUB.REGIONAL;
        case 3:
            return HUB.SMISTAMENTO;
        default:
            return HUB.NATIONAL;
    }
};

export const fromHubEntityToDTO = (hub: HUB): number => {
    switch (hub) {
        case HUB.NATIONAL:
            return 1;
        case HUB.REGIONAL:
            return 2;
        case HUB.SMISTAMENTO:
            return 3;
        default:
            return 2;
    }
};

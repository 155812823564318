import {
    PackRest,
    MutatePackRest,
} from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/pack_rest';
import { Pack } from '../interfaces/entity/pack';
import { fromLotProtoToEntity } from './lotMapper';
import { fromProductRestToEntity } from './productMapper';

export const fromPackDTOToEntitty = (dto: PackRest): Pack => {
    const pack: Pack = {
        id: dto?.id,
        barcode: dto?.barcode,
        recipient: dto?.recipient,
        address: dto?.address,
        cap: dto?.cap,
        province: dto?.province,
        city: dto?.city,
        phoneNumber: dto?.phoneNumber,
        email: dto?.email,
        note: dto?.note,
        receipt: dto?.receipt,
        product: fromProductRestToEntity(dto?.product),
        weight: dto?.weight,
        pricing: dto?.pricing,
        royalty: dto?.royalty,
        lot: fromLotProtoToEntity(dto?.lot),
        createdDate: dto?.createdDate,
        deletedDate: dto?.deletedDate,
        agencyId: dto?.agencyId,
    };
    return pack;
};

export const fromPacksDTOToEntities = (arg: PackRest[]): Pack[] => {
    return arg.map((p) => fromPackDTOToEntitty(p));
};

export const fromPackEntityToMutate = (arg: Pack): MutatePackRest => {
    const pack: MutatePackRest = {
        id: arg?.id,
        barcode: arg?.barcode,
        recipient: arg?.recipient,
        address: arg?.address,
        cap: arg?.cap,
        province: arg?.province,
        city: arg?.city,
        phoneNumber: arg?.phoneNumber,
        email: arg?.email,
        note: arg?.note,
        receipt: arg?.receipt,
        weight: arg?.weight,
        pricing: arg?.pricing,
        royalty: arg?.royalty,
        agencyId: arg?.agencyId,
        lotId: arg?.lot?.id,
        productId: arg?.product?.id,
    };
    return pack;
};

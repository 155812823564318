import { useEffect, useState } from 'react';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import { PackRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/pack_rest';

import { Get } from '../client';
import { Pack } from '../../interfaces/entity/pack';
import { fromPackDTOToEntitty } from '../../mapper/packMapper';
import { BaseFilter as BaseFilterDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/base_filter';
import { fromBaseFilterToDTOMapper } from '../../mapper/baseFilterMapper';
import { useModeContext } from '../../provideAppContext';
import { BaseFilter } from '../../interfaces/entity/baseFilter';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { Nullable } from '../../type/customType';
import { PACKS_BASE_PATH } from './config';

export const usePack = (
    id: string,
    defaultFilters: BaseFilter,
): [Pack, string] => {
    const [data, setData] = useState<Pack>(undefined);
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const modeContext = useModeContext();
    const [error, setError] = useState<Nullable<string>>(null);
    useEffect(() => {
        if (isNotUndefined(id) && isNotUndefined(defaultFilters)) {
            const res = Get<PackRest, BaseFilterDTO>(
                `${PACKS_BASE_PATH}/${id}`,
                token,
                modeContext,
                fromBaseFilterToDTOMapper(defaultFilters),
            );
            res.then((body) => {
                const pack = fromPackDTOToEntitty(body);
                setData(pack);
            }).catch((err) => {
                setError(err.getMessage());
                setData(null);
            });
        }
    }, [id, defaultFilters]);

    return [data, error];
};

import {
    LotRest,
    MutateLotRest,
} from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/lot_rest';
import { Lot as LotDTO } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/lot';
import { Lot } from '../interfaces/entity/lot';
import { fromHubDtoToEntity, fromHubEntityToDTO } from './hub';

export const fromLotDtoToEntity = (lotDTO: LotRest): Lot => {
    const lot: Lot = {
        id: lotDTO.id,
        agencyId: lotDTO.agencyId,
        deletedDate: lotDTO.deletedDate,
        createdDate: lotDTO.createdDate,
        distinctDate: lotDTO.distinctDate,
        firstArrivalBranch: fromHubDtoToEntity(lotDTO.firstArrivalBranch),
    };
    return lot;
};

export const fromLotsDtoToEntities = (arg: LotRest[]): Lot[] => {
    return arg.map((lot) => fromLotDtoToEntity(lot));
};

export const fromLotProtoToEntity = (lotDTO: LotDTO): Lot => {
    const lot: Lot = {
        id: lotDTO?.id,
        agencyId: lotDTO?.agencyId,
        deletedDate: lotDTO?.deletedDate,
        createdDate: lotDTO?.createdDate,
        distinctDate: lotDTO?.distinctDate,
        firstArrivalBranch: fromHubDtoToEntity(lotDTO?.firstArrivalBranch),
    };
    return lot;
};

export const fromLotToMutateLot = (lot: Lot): MutateLotRest => {
    const mutatelot: MutateLotRest = {
        id: lot?.id,
        agencyId: lot?.agencyId,
        firstArrivalBranch: lot?.firstArrivalBranch
            ? fromHubEntityToDTO(lot.firstArrivalBranch)
            : undefined,
        customerId: lot?.customer.id,
        distinctDate: lot?.distinctDate,
        packs: [],
    };
    return mutatelot;
};

import { createContext, ReactElement, ReactNode } from 'react';
import { User } from '../interfaces/entity/user';
import { useAuth } from './provideAuth';

export const AuthContext = createContext<IAuthContext>({
    user: null,
    isLogging: false,
});

interface IAuthContext {
    user: User;
    isLogging: boolean;
}

export const AuthProvider = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const [user, isLogging] = useAuth();

    return (
        <AuthContext.Provider value={{ user, isLogging }}>
            {children}
        </AuthContext.Provider>
    );
};

import { LotRest } from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/lot_rest';
import { useEffect, useState } from 'react';
import { Lot } from '../../interfaces/entity/lot';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import { fromHubEntityToDTO } from '../../mapper/hub';
import { fromLotDtoToEntity } from '../../mapper/lotMapper';
import { useModeContext } from '../../provideAppContext';
import { Nullable } from '../../type/customType';
import { ApiError } from '../../utils/customApiError';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { Post } from '../client';
import { LOTS_BASE_PATH } from './config';

export const useCreateLot = (
    defaultValue?: Lot,
): [Nullable<Lot>, Nullable<string>] => {
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const [data, setData] = useState<Nullable<Lot>>(defaultValue);
    const [error, setError] = useState<Nullable<string>>(null);
    const modeContext = useModeContext();
    useEffect(() => {
        if (isNotUndefined(defaultValue)) {
            const bodyFormData = new FormData();
            bodyFormData.append(
                'customerId',
                defaultValue?.customer?.id.toString(),
            );
            bodyFormData.append('agencyId', defaultValue?.agencyId.toString());

            bodyFormData.append(
                'distinctDate',
                defaultValue?.distinctDate.toString(),
            );

            bodyFormData.append(
                'firstArrivalBranch',
                fromHubEntityToDTO(defaultValue?.firstArrivalBranch).toString(),
            );

            bodyFormData.append('file', defaultValue?.file);

            const res = Post<FormData, LotRest>(
                LOTS_BASE_PATH,
                token,
                modeContext,
                bodyFormData,
            );
            res.then((body) => {
                setData(fromLotDtoToEntity(body));
            }).catch((err: ApiError) => {
                setError(err.getMessage());
                setData(null);
            });
        } else {
            setError(null);
            setData(defaultValue);
        }
    }, [defaultValue]);

    return [data, error];
};

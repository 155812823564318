import {
    MutatePackRest,
    PackRest,
} from '@fulmine/grpc-api-stubs/dist/proto/fulmine/v1/pack_rest';
import { useEffect, useState } from 'react';
import { Pack } from '../../interfaces/entity/pack';
import { LocalStorageKey } from '../../localstorage/const';
import { useLocalStorage } from '../../localstorage/provideLocalStorage';
import {
    fromPackDTOToEntitty,
    fromPackEntityToMutate,
} from '../../mapper/packMapper';
import { useModeContext } from '../../provideAppContext';
import { Nullable } from '../../type/customType';
import { ApiError } from '../../utils/customApiError';
import { isNotUndefined } from '../../utils/interfaceUtils';
import { Post } from '../client';
import { PACKS_BASE_PATH } from './config';

export const useCreatePack = (
    defaultValue?: Pack,
): [Nullable<Pack>, Nullable<string>] => {
    const [token] = useLocalStorage<string>(LocalStorageKey.TOKEN, null);
    const [data, setData] = useState<Nullable<Pack>>(defaultValue);
    const [error, setError] = useState<Nullable<string>>(null);
    const modeContext = useModeContext();
    useEffect(() => {
        if (isNotUndefined(defaultValue)) {
            const res = Post<MutatePackRest, PackRest>(
                PACKS_BASE_PATH,
                token,
                modeContext,
                fromPackEntityToMutate(defaultValue),
            );
            res.then((body) => {
                setData(fromPackDTOToEntitty(body));
            }).catch((err: ApiError) => {
                setError(err.getMessage());
                setData(null);
            });
        } else {
            setError(null);
            setData(defaultValue);
        }
    }, [defaultValue]);

    return [data, error];
};

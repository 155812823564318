import { ThemeProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import MainNavBar from './navigation/navBar';
import theme from './theme';
import { AuthProvider } from './shared/auth/authProvider';
import GlobalStyles from '@mui/material/GlobalStyles';
import { RequireAuth } from './shared/auth/requrieAuth';
import { AppContextProvider } from './shared/provideAppContext';
import './main.css';
import { AppContainer } from 'react-hot-loader';
import * as React from 'react';
import { hot } from 'react-hot-loader/root';

const Root = (): React.ReactElement => {
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <GlobalStyles
                    styles={{
                        body: { margin: 0 },
                    }}
                />
                <BrowserRouter>
                    <AppContextProvider>
                        <AuthProvider>
                            <RequireAuth>
                                <MainNavBar />
                            </RequireAuth>
                        </AuthProvider>
                    </AppContextProvider>
                </BrowserRouter>
            </ThemeProvider>
        </React.StrictMode>
    );
};
export default hot(Root);
if (module.hot) {
    module.hot.accept();
}

import { useEffect, useState } from 'react';
import { User } from '../interfaces/entity/user';
import { provideConfig } from '../provide_config';
import axios, { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '../localstorage/provideLocalStorage';
import { LocalStorageKey } from '../localstorage/const';
import { UserDTO } from '../interfaces/dto/userDTO';
import { fromDtoToEntity } from '../mapper/userMapper';

export const useAuth = (): [User, boolean] => {
    const [auth, setAuth] = useState<User | null>(null);
    const [isLogging, setLogging] = useState(true);
    const config = provideConfig();
    const [searchParams] = useSearchParams();
    const [accessToken, setAccessToken] = useLocalStorage<string>(
        LocalStorageKey.TOKEN,
        null,
    );

    const token = searchParams.get('token');

    useEffect(() => {
        axios({
            method: 'GET',
            url: config.checkTokenUrl,
            headers: {
                Authorization: `Bearer  ${
                    token !== null ? token : accessToken
                }`,
            },
        })
            .then((response: AxiosResponse<UserDTO>) => {
                setAuth(fromDtoToEntity(response.data));
                setAccessToken(token !== null ? token : accessToken);
                setLogging(false);
            })
            .catch(() => {
                setAuth(null);
                setLogging(false);
                localStorage.clear();
            });
    }, []);

    return [auth, isLogging];
};

import { Maybe } from '../type/customType';
import { isUndefined } from './interfaceUtils';

export const extractResponseMessage = (
    message: Maybe<string | string[]>,
): Maybe<string> => {
    if (isUndefined(message)) {
        return undefined;
    } else if (typeof message === 'string') {
        return message;
    } else {
        return message.join(',');
    }
};

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import { FC, useEffect, memo, ReactElement, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListRouter from './router';
import { CustomRoutes } from '../routes/routes';
import Container from '@mui/material/Container';

/**
 * use onClose function to close drawer
 */
export interface ILeftDrawer {
    open: boolean;
    onClose(): void;
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const LeftDrawer: FC<ILeftDrawer> = (props: ILeftDrawer): ReactElement => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={props.onClose}>
                        {theme.direction === 'ltr' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <ListRouter onItemClick={props.onClose} />
            </Drawer>
            <Container maxWidth="xl" fixed className="pageContainer">
                <CustomRoutes />
            </Container>
        </>
    );
};

export default memo(LeftDrawer);

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    forwardRef,
    ReactElement,
    memo,
    PropsWithChildren,
    ForwardedRef,
} from 'react';

const AlertComponent = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props: PropsWithChildren<AlertProps>,
    ref: ForwardedRef<HTMLDivElement>,
): ReactElement {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default memo(AlertComponent);

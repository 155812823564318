import {
    ReactElement,
    SyntheticEvent,
    useEffect,
    useState,
    FormEvent,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import { Lot } from '../../../shared/interfaces/entity/lot';
import { useCustomers } from '../../../shared/api/customer/getAll';
import { HUB } from '../../../shared/interfaces/entity/hub';
import { Customer } from '../../../shared/interfaces/entity/customer';
import { SelectEntity } from '../../../shared/interfaces/entity/utils/selectEntity';
import DateTimeComponent from '../../input/dateTime';
import HubSelectComponent from '../../input/hubSelect';
import './lotForm.css';
import { Maybe } from '../../../shared/type/customType';
import { existAll, isNull } from '../../../shared/utils/interfaceUtils';
import { AgencySelectComponent } from '../../input/agencySelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { useModeContext } from '../../../shared/provideAppContext';
import UploadButtton from './../uploadButtton';
import Box from '@mui/material/Box';
import PageHeadComponent from '../../pages/head';
import CircularProgress from '@mui/material/CircularProgress';

interface ILotFormComponent {
    readonly onSubmit?: (data: Lot) => void;
}

export const LotFormComponent = (props: ILotFormComponent): ReactElement => {
    const [lot, setLot] = useState<Maybe<Lot>>(undefined);
    const modeContext = useModeContext();

    const [customers] = useCustomers([]);

    useEffect(() => {
        setLot({ ...lot, distinctDate: new Date().toDateString() });
    }, []);

    /**** handle set properties*/

    const onDateChange = (date: Date): void => {
        setLot({ ...lot, distinctDate: date.toDateString() });
    };

    const onHubChange = (hub: HUB): void => {
        setLot({ ...lot, firstArrivalBranch: hub });
    };

    const onAgencySeletect = (value: SelectEntity): void => {
        setLot({ ...lot, agencyId: isNull(value) ? undefined : value.id });
    };

    const onCustomerSeletect = (value: Customer): void => {
        setLot({
            ...lot,
            customer: value,
        });
    };

    const onFileChange = (file: File): void => {
        setLot({ ...lot, file });
    };

    /*****/

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        props.onSubmit(lot);
    };

    const formIsValid = (): boolean => {
        return existAll([
            lot?.agencyId,
            lot?.customer,
            lot?.firstArrivalBranch,
            lot?.distinctDate,
        ]);
    };

    return (
        <>
            <PageHeadComponent pageTitle="Nuovo Lotto" />
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={(e: FormEvent<HTMLFormElement>): void =>
                    handleSubmit(e)
                }
                sx={{ mt: 3 }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            disablePortal
                            id="customer"
                            options={customers}
                            renderInput={(params): ReactElement => (
                                <TextField
                                    {...params}
                                    label="Cliente"
                                    required={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {modeContext.mode({
                                                    loading:
                                                        customers.length ===
                                                            0 && (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ),
                                                })}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            noOptionsText="nessun customer"
                            onChange={(
                                event: SyntheticEvent<Element, Event>,
                                value: Customer,
                            ): void => onCustomerSeletect(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AgencySelectComponent
                            required={true}
                            onSelectChange={(value): void =>
                                onAgencySeletect(value)
                            }
                        />
                    </Grid>
                    <Grid item md={6}></Grid>

                    <Grid item xs={6} md={3} className="dateTimeWidth">
                        <DateTimeComponent
                            label="Data distinta"
                            onDateChange={onDateChange}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <HubSelectComponent
                            onSelect={onHubChange}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={3}
                    sx={{ mt: 2, mb: 2 }}
                >
                    <Grid item md={3}>
                        <UploadButtton
                            disabled={modeContext.mode({ loading: true })}
                            onFileChange={(e): void => onFileChange(e)}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </Grid>
                    <Grid item md={3}>
                        <LoadingButton
                            loading={modeContext.mode({ loading: true })}
                            type="submit"
                            variant="contained"
                            className="submitButton mainButton"
                            startIcon={<SaveIcon />}
                            disabled={!formIsValid()}
                        >
                            Salva
                        </LoadingButton>
                    </Grid>
                    <Grid item md={6}></Grid>
                </Grid>
            </Box>
        </>
    );
};

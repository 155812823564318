import { ErrorResponse } from '../interfaces/dto/errorDTO';
import { extractResponseMessage } from './api';

export class ApiError extends Error {
    apiError: ErrorResponse | null = null;
    constructor(error: ErrorResponse) {
        super(extractResponseMessage(error.message));
        Object.setPrototypeOf(this, ApiError.prototype);
        this.apiError = error;
    }

    getError(): ErrorResponse {
        return this.apiError;
    }

    getMessage(): string {
        return this.message;
    }
}

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { FC, ReactElement, memo } from 'react';

export interface INotification {
    notificationCount: number;
    message?: string;
}

export const NotificationComponent: FC<INotification> = (
    props: INotification,
): ReactElement => {
    return (
        <>
            <IconButton size="large" aria-label={props.message} color="inherit">
                <Badge badgeContent={props.notificationCount} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
        </>
    );
};

export default memo(NotificationComponent);

export enum OperatorDTO {
    OPERATOR_INVALID = 0,
    OPERATOR_EQ = 1,
    OPERATOR_GT = 2,
    OPERATOR_LT = 3,
    OPERATOR_GTE = 4,
    OPERATOR_LTE = 5,
    OPERATOR_LIKE = 6,
    OPERATOR_IN = 7,
}

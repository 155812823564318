import { useState, useEffect } from 'react';
import { Maybe, Nullable, Setter } from '../type/customType';
import { StorageKeys } from './const';

/**
 * basic local storage hook set null to clear
 * @param key local storage key
 * @returns value and state dispatcher
 */
export const useLocalStorage = <T,>(
    key: StorageKeys,
    dafaultValue: T,
): [T, Setter<T>] => {
    const [value, setValue] = useState(() => {
        return localStorage.getItem(key)
            ? (parseJSON(localStorage.getItem(key)) as T)
            : dafaultValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};
/**
 * parse json object
 * @param value json
 * @returns T
 */
function parseJSON<T>(value: Nullable<string>): Maybe<T> {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '');
    } catch {
        return undefined;
    }
}

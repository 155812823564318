import { UserDTO } from '../interfaces/dto/userDTO';
import { User } from '../interfaces/entity/user';

export const fromDtoToEntity = (user: UserDTO): User => {
    return {
        username: user.username,
        email: user.email,
        name: user.name,
        isActive: user.attivo,
        parcel: user.parcel,
    } as User;
};

import { memo, ReactElement, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { Nullable } from '../../shared/type/customType';
import { isNotUndefined } from '../../shared/utils/interfaceUtils';

interface IDateTimeComponent {
    readonly label: string;
    readonly onDateChange?: (date: Date) => void;
    readonly size?: 'small' | 'medium';
    readonly value?: Date;
}

/**
 * Custom DateTime picker
 * @param props IDateTimeComponent
 * @returns DateTime picker
 */
const DateTimeComponent = (props: IDateTimeComponent): ReactElement => {
    const [value, setValue] = useState<Nullable<Date>>(new Date());

    const handleChange = (newValue: Nullable<Date>): void => {
        setValue(newValue);
        newValue && props?.onDateChange(newValue);
    };

    useEffect(() => {
        if (isNotUndefined(props.value)) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={props.label}
                    value={value}
                    onChange={handleChange}
                    renderInput={(params): ReactElement => (
                        <TextField size={props.size ?? 'medium'} {...params} />
                    )}
                    ampm={false}
                />
            </LocalizationProvider>
        </>
    );
};

export default memo(DateTimeComponent);

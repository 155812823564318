import { Configuration } from './config';

/**
 * provide configiration from env
 * @returns Configuration
 */
export function provideConfig(): Configuration {
    return {
        checkTokenUrl: process.env.REACT_APP_CHECK_TOKEN,
        baseApiUrl: process.env.REACT_APP_BASE_API_URL,
        prefix: Boolean(process.env.REACT_APP_BASE_API_ENABLE_PREFIX),
    };
}
